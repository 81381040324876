.node-list {
    width: 70%;
    display: block;
    padding: 1.5em 0;

    .node-item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1em;
        margin: 1em 0;
        border-bottom: 2px dotted $glight;
        &:last-of-type { border-bottom: none; }

        .img-container {
            width: 150px;
            display: inline-block;
            margin-right: 1.5em;
            img { width: 100%; height: auto; display: block; }
        }

        .node-content {
            flex: 1;
            padding-top: 1em;

            h2 {
                display: block;
                margin-bottom: .5em;
                a {
                    font-size: 1.3em;
                    font-weight: bold;
                    color: $bprimary;
                    &:hover { text-decoration: underline; }
                }
            }

            .page-body {
                display: block;
                margin: .75em 0;
            }

            .btn { display: inline-block; padding: .7em 1.1em; }
        }
    }
}

@include tablet {
    .node-list {
        width: 100%;
        padding: 1em;
    }
}

@include tiny-mobile {
    .node-list {
        .node-item {
            .img-container { display: none; }
        }
    }
}