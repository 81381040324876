.breadcrumb {
    width: 100%;
    display: block;
    padding: .75em 0;

    span {
        font-size: .9em;
        margin-right: .5em;
        display: inline;
        color: $bblack;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;
        &:before {
            color: $gray;
            margin-right: .75em;
            content: '\f105';
            font-family: $ifaf;
        }
        &:first-child:before {
            display: none;
        }

        a {
            font-weight: normal;
            color: $gray;
            &:hover { text-decoration: underline; }
        }
    }
}