.page-title-blog {
    width: 100%;
    height: 130px;
    margin-top: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E5EEF7;

    h1.page-title {
        font-size: 50px;
        font-weight: 500;
        color: #005DCF;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0px 0px 1px rgba($bblack, .2);
    }
}

.page-title-post {
    position: relative;
    width: 100%;
    height: 400px;
    padding-top: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-attachment: fixed;
    background-position: 100% 100%;
    background-repeat: no-repeat;

    h1.page-title {
        max-width: 85%;
        color: #fff;
        font-size: 40px;
        font-weight: 500;
        line-height: 1.25em;
        text-align: left;
        text-transform: uppercase;
        text-shadow: 0px 0px 1px rgba($bblack, .2);
    }

    &::before {
        position: absolute;
        top: 0; left: 0;
        display: block;
        width: 100vw;
        height: 400px;
        content: '';
        background-color: rgba(#143762, .8);
    }
}

.blog-grid {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;

    .grid-item {
        flex: 0 0 50%;
        padding: 2em 1em;
        box-sizing: border-box;

        .lead-image {
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 200px;
            overflow: hidden;

            img { display: block; width: 100%; }
        }

        .post-header {
            margin: 2em 0;

            .post-title {
                color: #00017C;
                font-size: 25px;
                font-weight: 500;
                line-height: 1.5em;

                a {
                    color: #00017C;

                    &:hover { text-decoration: underline; }
                }
            }

            .post-tags {
                // margin: 1em 0;

                li {
                    display: inline-block;
                    margin: 0 0 0 .5em;
                    padding: 0 .5em 0 0;
                    font-size: 16px;
                    font-weight: 500;
                    border-right: 1px solid #005DCF;

                    &:first-child { margin-left: 0; }
                    &:last-child { padding-right: 0; border-right: none; }

                    a {
                        color: #005DCF;

                        &:hover { text-decoration: underline; }
                    }
                }
            }
        }
    }
}

.featured-posts {
    position: relative;
    padding-top: 4em;
    background-color: #E5EEF7;

    &::before {
        position: absolute;
        top: 0; left: 50%; z-index: 1;
        margin-left: -10px;
        content: '';
        display: block;
        @include triangle(down, 20px, #fff);
    }

    .featured-title {
        display: block;
        margin-bottom: 1em;
        font-size: 30px;
        font-weight: 500;
        color: #005DCF;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0px 0px 1px rgba($bblack, .2);
    }
}
