.page-herospace {
    width: 100%;
    height: 700px;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    background-position: 100% 100%;
    background-repeat: no-repeat;

    .wrapper {
        display: flex;
        align-items: center;
    }

    .page-titles {
        width: 60%;
        height: 770px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -3em;
    }

    h1.page-title {
        display: block;
        font-size: 50px;
        font-weight: 500;
        line-height: 1.3em;
        color: $bwhite;
        text-shadow: 1px 2px 1px rgba($bblack, .4);
        /*background-color: rgba(0, 93, 207, 0.6);
        padding: 30px;*/
    }

    h2.page-subtitle {
        display: block;
        margin-top: 25px;
        font-size: 20px;
        font-weight: normal;
        line-height: 1.5em;
        color: $bwhite;
        text-shadow: 1px 2px 1px rgba($bblack, .4);
        /*background-color: rgba(0, 93, 207, 0.6);
        padding: 30px;*/
        &:empty {
            display: none;
        }
    }

    .scroll-indicator {
        position: absolute;
        bottom: 80px;
        left: 50%;
        margin-left: -35px;
        @include transition(bottom .1s ease-in-out);
        &:hover { bottom: 78px; }
    }

    &.category-herospace {
        height: 440px;

        .wrapper {
            height: 440px;
        }

        .page-titles {
            height: 440px;
            margin-top: 3em;
        }

        h1.page-title {
            text-transform: uppercase;
        }
    }
}

.page-title-bg {
    width: 100%;
    height: 200px;
    display: block;
    background-image: url(../../uploads/images/random/herospace-3.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position: 100% 100%;
    background-repeat: no-repeat;

    .wrapper {
        padding-top: 135px;
    }

    h1.page-title {
        font-size: 40px;
        font-weight: 500;
        color: $bwhite;
        text-shadow: 1px 2px 1px rgba($bblack, .2);
    }
}

.single-container {
    display: block;

    .page-body {
        display: block;
        max-width: 960px;
        margin: 3em 0;
    }
}

.popup {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    color: $bwhite;
    background: rgba($bprimary-lighter, .95);
    text-align: left;

    .popup-wrapper {
        height: 100vh;
        display: flex;
        justify-content: space-between;
    }

    .close-btn {
        display: inline-block;
        height: 44px;
        font: normal 14px/44px $bfaf;
        background: none;
        border: none;
        &:before, &:after {
            content: '';
            width: 44px;
            height: 44px;
            display: inline-block;
            background: url(../img/delete.svg) no-repeat;
        }

        span {
            color: $bwhite;
            text-transform: uppercase;
            position: relative;
            top: -16px;
            display: inline-block;
            line-height: 44px;
            margin-left: .5em;
        }

        &.close-btn-left {
            &:after { display: none; }
            span { margin-left: .5em; }
        }
        &.close-btn-right {
            &:before { display: none; }
            span { margin-right: .5em; }
        }
    }

    .person-image {
        width: 30%;
        flex-basis: 30%;
        padding-top: 5em;
        text-align: right;
        background: $bprimary-lighter;

        h2 {
            display: block;
            font-size: 26px;
            line-height: 1.5em;
            text-transform: uppercase;
        }

        h6 {
            display: block;
            font-size: 16px;
            font-weight: normal;
        }

        .img-container {
            display: block;
            text-align: right;
            margin: 2em 0 5em 0;

            img {
                float: right;
                max-width: 70%;
                display: block;
            }
        }

        .btn-container {
            clear: both;
            padding: 2em 0 0 30%;
            text-align: center;
        }
    }

    .person-content {
        flex: 1;
        padding-top: 5em;

        .person-body {
            display: block;
            margin: 5em 7em 0 3em;

            p, div {
                font-size: 18px;
                line-height: 1.7em;
                color: $bwhite;
                margin-bottom: .75em;
            }
        }

        .btn-container {
            position: absolute;
            top: 2em;
            right: 2em;
            text-align: right;
        }
    }
}

@include tablet {
    .page-herospace {
        height: auto;

        .page-titles {
            width: 100%;
            height: auto;
            margin-top: 5em;
            padding: 2em;
        }

        h1.page-title {
            font-size: 40px;
        }

        .scroll-indicator {
            display: none;
        }

        &.category-herospace {
            height: auto;

            .wrapper { height: auto; }
            .page-titles { height: auto; margin-top: 5em; }
        }
    }

    .single-container {
        .page-body {
            max-width: 100%;
            margin: 2em;
        }
    }

    .popup {
        overflow-y: auto;
    }
}

@include mobile {
    .page-herospace {
        .page-titles {
            margin-top: 7em;
            padding: 2em 1em;
        }
    }

    .page-title-bg {
        height: auto;

        .wrapper {
            padding: 145px 1em 1em 1em;
        }

        h1.page-title {
            font-size: 32px;
        }
    }

    .single-container {
        .page-body { margin: 1em; }
    }

    .popup {
        .person-image {
            width: 38%;
            flex-basis: 38%;

            h2 { font-size: 22px; }
            h6 { font-size: 15px; }
            .img-container img { max-width: 90%; }
            .btn-container { display: none; }
        }

        .person-content {
            padding-top: 0;

            .btn-container { top: 1em; right: 1em; }
            .person-body {
                margin: 5em 1em 1em 2em;
                p, div { font-size: 16px; line-height: 1.5em; }
            }
        }
    }
}

@include tiny-mobile {
    .page-herospace {
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba($bprimary-dark, .5);
        }

        h1.page-title { font-size: 26px; }
        h2.page-subtitle { font-size: 17px; }
    }

    .popup {
        .popup-wrapper {
            flex-direction: column;
        }

        .person-image {
            width: 100%;
            flex-basis: 100%;
            text-align: left;
            padding: 5em 0 0 1em;

            .img-container { display: none; }
        }

        .person-content {
            .person-body { margin: 2em 1em; }
        }
    }
}

@include tiny-mobile {
    .page-herospace {
        .page-titles {
            margin-top: 115px;
            padding: 1.5em .75em;
        }

        &.category-herospace {
            .page-titles {
                margin-top: 115px;
            }
        }
    }
}
