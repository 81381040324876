.quote-container {
    position: relative;
    z-index: 40;
    display: block;
    padding: 3em 0;
    background: $bprimary-lighter;
    &:after {
        content: '';
        position: absolute;
        bottom: -40px;
        left: 50%;
        margin-left: -20px;
        @include triangle('down', 20px, $bprimary-lighter);
    }

    .wrapper {
        text-align: center;
    }

    .alert {
        text-align: left;
        background: $bwhite;
        border-radius: 15px;
    }

    .quote-title {
        display: block;
        font-size: 32px;
        font-weight: normal;
        color: $bwhite;
        text-transform: uppercase;
        margin-bottom: 1em;
    }

    .btn-container {
        display: block;
        text-align: center;
    }

    .btn {
        display: inline-block;
        margin: 0 auto;
        &.btn-purple {
            text-transform: uppercase;
            font-size: 1em;
            padding: 1em 1.5em;
        }
    }
}

.quote-form {
    width: 660px;
    margin: 2em auto;
    padding: 2em;
    background: $bprimary-dark-alt;
    border-radius: 7px;

    .form-group {
        display: block;
        margin: 1em 0;

        label {
            display: block;
            font-size: 1.2em;
            line-height: 2em;
            color: $bwhite;
            padding-left: .5em;
            text-align: left;
        }

        .form-input {
            width: 100%;
            height: 58px;
            font: normal 1.1em $bfaf;
            background: $bwhite;
            border: none;
            border-radius: 15px;
            padding: 0 .75em;
            outline: none;
        }

        .form-textarea {
            height: 180px;
            resize: none;
            padding-top: .75em;
            padding-bottom: .75em;
        }
    }

    .btn-container {
        padding: 1em 0;

        .btn {
            &.btn-purple {
                font-size: 1.2em;
            }
        }
    }

    .quote-bottom-info {
        display: block;
        color: $bwhite;
        line-height: 1.7em;
        margin-top: 1.5em;

        h5 { font-size: 1.3em; display: block; margin-bottom: .5em; }
    }
}

@include mobile {
    .quote-form {
        width: 80%;

        .form-group {
            .form-input {
                height: 40px;
                border-radius: 4px;
            }

            .form-textarea {
                height: 120px;
            }
        }
    }
}

@include tiny-mobile {
    .quote-title {
        font-size: 24px;
    }

    .quote-form {
        width: 92%;

        .form-group {
            margin: .5em 0;

            label {
                padding-left: 0;
            }
        }
    }
}
