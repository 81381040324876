@mixin desktop {
    @media (max-width: 1310px) {
        @content;
    }
}

@mixin tiny-desktop {
    @media (max-width: 1279px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 1000px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 700px) {
        @content;
    }
}

@mixin tiny-mobile {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin handheld-mobile {
    @media (max-width: 360px) {
        @content;
    }
}

@mixin linear-gradient($from-color, $to-color) {
    background-color: $from-color;
    background-image: -webkit-gradient(linear, left top, left bottom, from($from-color), to($to-color));
    background-image: -webkit-linear-gradient($from-color, $to-color);
    background-image: -moz-linear-gradient($from-color, $to-color);
    background-image: -ms-linear-gradient($from-color, $to-color);
    background-image: -o-linear-gradient($from-color, $to-color);
    background-image: linear-gradient($from-color, $to-color);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr='#{$from-color}', EndColorStr='#{$to-color}');
}

@mixin transition($args) {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);

    -webkit-transition: $args;
    -moz-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin triangle($direction: "down", $size: 20px, $color: #000) {
    width: 0;
    height: 0;
    border-bottom: $size solid #{set-triangle-color($direction, "bottom", $color)};
    border-right: $size solid #{set-triangle-color($direction, "right", $color)};
    border-left: $size solid #{set-triangle-color($direction, "left", $color)};
    border-top: $size solid #{set-triangle-color($direction, "top", $color)};
}

@mixin triangle-ra($width, $height, $direction, $color) {
    width: 0;
    height: 0;
    border-style: solid;

    @if $direction == bottom-left {
        border-color: transparent transparent transparent $color;
        border-width: $height 0 0 $width;
    }

    @if $direction == bottom-right {
        border-color: transparent transparent $color transparent;
        border-width: 0 0 $height $width;
    }

    @if $direction == top-left {
        border-color: $color transparent transparent transparent;
        border-width: $height $width 0 0;
    }

    @if $direction == top-right {
        border-color: transparent $color transparent transparent;
        border-width: 0px $width $height 0px;
    }
}

@function set-triangle-color($direction, $side, $color) {
    @if $direction == "left" and $side == "right"
     or $direction == "right" and $side == "left"
     or $direction == "down" and $side == "top"
     or $direction == "up" and $side == "bottom" {
        @return $color
    } @else {
        @return "transparent";
    }
}
