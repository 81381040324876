$wrapper-width: 1300px;

$bblack: #4a4a4a;
$bwhite: #fff;

$bprimary: #00017c;
$bprimary-light: #005dcf;
$bprimary-light-alt: #4388dc;
$bprimary-lighter: #4a90e2;
$bprimary-lightest: #c1d8f2;
$bprimary-dark: #143762;
$bprimary-dark-alt: #2c64a6;
$bprimary-darker: #112d4e;

$bsecondary: #0cffff;
$bsecondary-dark: #50e3c2;

$bpurple: #a83597;

$bsuccess: #4eb510;
$bwarning: #edce41;
$bdanger: #df4558;

$gray: #e5eef7;
$glight: #f5f8fb;

$bfaf: 'MuseoWeb', Helvetica, Arial, sans-serif;
$ifaf: 'FontAwesome';