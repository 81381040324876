.scroll-indicator {
    width: 71px;
    height: 71px;
    display: block;
    // background: url(../img/scroll.svg) no-repeat;
    // background-size: 100%;
    cursor: pointer;
}

.start-indicator {
    width: 86px;
    height: 71px;
    display: block;
    // background: url(../img/start.svg) no-repeat;
    // background-size: 100%;
    cursor: pointer;
    @include transition(margin-left .1s ease-in-out);
    &:hover { margin-left: 3px; }
}

.more-indicator {
    width: 85px;
    height: 72px;
    display: block;
    // background: url(../img/more-hu.svg) no-repeat;
    // background-size: 100%;
    cursor: pointer;
    @include transition(margin-left .1s ease-in-out);
    &:hover { margin-left: 3px; }
}

.lang-en .more-indicator { background: url(../img/more-en.svg) no-repeat; }
.lang-de .more-indicator { background: url(../img/more-de.svg) no-repeat; }
.lang-ru .more-indicator { background: url(../img/more-ru.svg) no-repeat; }
.lang-ro .more-indicator { background: url(../img/more-ro.svg) no-repeat; }
.lang-ar .more-indicator { background: url(../img/more-ar.svg) no-repeat; }

@include tablet {
    .scroll-indicator {
        width: 55px;
        height: 55px;
    }

    .start-indicator, .more-indicator {
        width: 70px;
        height: 60px;
    }
}
