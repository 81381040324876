.pagination {
    text-align: center;
    margin: 2em 0;
    display: flex;
    align-items: baseline;

    ul {
        margin: 0 auto;
        display: flex;
        justify-content: space-around;

        li {
            margin: 0 .25em;
            list-style: none;

            a {
                color: $bprimary;
                padding: .25em .6em;
                border: none;
                background: $bwhite;
                &:hover { color: $bwhite; background: $bprimary; text-decoration: none; }
            }

            &.active a {
                color: $bwhite;
                border: none;
                background: $bprimary;
            }

            &.disabled a {
                cursor: not-allowed;
                color: $bwhite;
                border: none;
                background: lighten($bprimary, 20%);
            }

            &:first-child {
                margin-left: 0;
                a { color: $bwhite; background: none; }
            }
            &:last-child {
                margin-right: 0;
                a { color: $bwhite; background: none; }
            }
        }
    }
}