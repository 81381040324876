.scroll-dots {
    width: 16px;
    height: 120px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 8em;
    right: 3em;
    z-index: 40;
    text-align: center;

    .dot {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 10px;
        background: $bwhite;
        border-radius: 50%;
        opacity: .5;
        cursor: pointer;
        &.is-selected {
            width: 16px;
            height: 16px;
            opacity: 1;
        }
    }
}

.scroll-section {
    width: 100%;
    height: 100vh;
    position: relative;
    display: block;
    overflow: hidden;

    .scroll-section-title {
        display: block;
        font-size: 50px;
        font-weight: normal;
        margin-top: 1em;
        text-align: center;
    }

    .scroll-indicator {
        margin: 2em 0;
    }

    .wrapper {
        overflow: hidden;
    }
}

.herospace {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;

    .hero-slide {
        width: 100%;
        height: 100vh;
        position: relative;
        background-size: cover;
        background-position: 100% 100%;
        background-repeat: no-repeat;

        .wrapper {
            height: 100vh;
            display: flex;
            align-items: center;
            text-align: left;
        }

        h2 {
            font-size: 50px;
            font-weight: 500;
            line-height: 1.7em;
            color: $bwhite;
            text-shadow: 1px 2px 2px rgba($bblack, .2);
            margin-top: 1em;

            b {
                display: block;
                font-size: 90px;
                font-weight: 500;
            }
        }
    }

    .scroll-indicator {
        position: absolute;
        left: 50%;
        bottom: 3em;
        z-index: 60;
        margin-left: -35px;
    }

    .flickity-page-dots {
        width: $wrapper-width;
        text-align: left;
        padding-left: 3em;
        bottom: 4em;
        left: 50%;
        margin-left: -($wrapper-width/2) - 50px;

        .dot {
            position: relative;
            background: $bwhite;
            opacity: .5;
            &.is-selected {
                width: 16px;
                height: 16px;
                background: $bwhite;
                opacity: 1;
                top: 3px;
            }
        }
    }
}

.trips {
    height: 93vh;
    background: url(../img/bg-trips.jpg) no-repeat fixed 100% 100%;
    background-size: cover;
    color: $bwhite;

    .wrapper {
        height: 93vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .trip-steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        margin-top: 3em;

        .trip-step {
            align-self: flex-start;
            width: 25%;
            display: inline-block;
            margin: 0 3em;

            .trip-icon {
                display: block;
                text-align: center;
                margin-bottom: 1em;

                img {
                    max-width: 80%;
                    display: inline-block;
                    margin: 0 auto;
                }
            }

            h2 {
                display: block;
                font-size: 24px;
                font-weight: normal;
                line-height: 1.3em;
                text-align: center;
                text-transform: uppercase;
            }

            ul {
                margin: 1em 0 0 0;
                list-style: none;

                li {
                    position: relative;
                    font-size: 18px;
                    line-height: 1.5em;
                    padding: .5em 0 .5em 1em;
                    &:before {
                        content: '\f105';
                        font-family: $ifaf;
                        position: absolute;
                        top: .5em;
                        left: 0;
                        color: $bsecondary;
                    }
                }
            }
        }
    }
}

.steps {
    color: $bwhite;

    .step-carousel {
        width: 100%;
        height: 93vh;
        display: block;

        .step-carousel-slide {
            width: 100%;
            height: 93vh;
            background-size: cover;
            background-repeat: no-repeat;

            .wrapper {
                height: 93vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .step-grid {
                flex: 1;
            }

            .step-item-wrapper {
                height: 60vh;
                display: flex;
                justify-content: space-between;
                align-items: center;

                img {
                    width: 380px;
                    display: inline-block;
                }

                ul {
                    margin-left: 2em;
                    list-style: none;

                    li {
                        position: relative;
                        padding: 0 0 0 1em;
                        font-size: 32px;
                        font-weight: normal;
                        line-height: 1.5em;
                        color: $bwhite;

                        &:before {
                            content: '\f105';
                            font-family: $ifaf;
                            position: absolute;
                            top: 0;
                            left: 0;
                            color: $bsecondary;
                        }
                    }
                }
            }

            .start-indicator { margin-bottom: 5em; }
        }
    }

    .step-grid {
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        flex: 1;
        padding: 1em 0;

        .step-item {
            width: 23%;
            display: inline-block;
            margin: 0 1em;
            text-align: center;

            span {
                width: 265px;
                height: 265px;
                display: block;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                text-indent: -9999px;
            }
            &:nth-child(1) span { background-image: url(../img/step-1.png); }
            &:nth-child(2) span { background-image: url(../img/step-2.png); }
            &:nth-child(3) span { background-image: url(../img/step-3.png); }
            &:nth-child(4) span { background-image: url(../img/step-4.png); }

            h2 {
                font-size: 30px;
                font-weight: normal;
                color: $bwhite;
                text-shadow: 1px 2px 1px rgba($bblack, .2);
                margin-left: -1em;
            }
        }
    }

    .start-indicator, .scroll-indicator {
        margin-bottom: 3em;
    }
}

.services-container {
    height: auto;
    display: block;
    background: none;

    .services-title {
        display: block;
        font-size: 50px;
        font-weight: normal;
        line-height: 1.5em;
        color: $bprimary-dark;
        text-align: center;
        padding: 1em 0;
    }

    .service-section {
        position: relative;
        display: block;
        padding-top: 3.5em;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -20px;
            @include triangle('down', 20px, $gray);
        }
        &:nth-of-type(even) {
            background: $gray;
            &:before { border-top-color: $bwhite; }
        }

        .service-section-title {
            display: block;
            font-size: 30px;
            font-weight: normal;
            color: $bprimary-light;
            text-transform: uppercase;
        }
    }

    .bubble-list {
        background: none;
    }
}

@include tiny-desktop {
    .scroll-section {
        .scroll-section-title {
            font-size: 36px;
        }
    }

    .herospace {
        .scroll-indicator {
            bottom: 0;
        }

        .hero-slide {
            h2 {
                font-size: 42px;
                margin-top: -1em;

                b { font-size: 72px; }
            }
        }
    }

    .trips {
        .trip-steps {
            .trip-step {
                width: 30%;
                margin: 0 1.5em;
                padding: 0 1em;

                .trip-icon img {
                    max-width: 80px;
                }

                h2 {
                    font-size: 21px;
                }

                ul {
                    width: 100%;
                    margin-top: .5em;

                    li {
                        font-size: 16px;
                        line-height: 1.4em;
                        padding: .4em 0 .4em .75em;
                    }
                }
            }
        }

        .scroll-indicator {
            margin: 0 0 3em 0;
        }
    }
}

@include tablet {
    .steps {
        .step-grid {
            .step-item {
                margin: 0 2.5em;

                span {
                    width: 100%;
                    height: 80px;
                    text-indent: 0;
                    line-height: 70px;
                    text-align: center;
                    font-size: 3em;
                    font-weight: bold;
                    background: none;
                    color: $bwhite;
                }

                h2 {
                    margin-left: 0;
                }
            }
        }

        .step-carousel {
            .step-carousel-slide {
                .step-item-wrapper {
                    img { width: 200px; }
                    h4 { font-size: 36px; margin-left: 1em; }
                }
            }
        }
    }
}

@include mobile {
    .scroll-dots {
        display: none;
    }

    .scroll-section {
        height: auto;

        .wrapper {
            height: auto;
        }
    }

    .herospace {
        height: auto;

        .scroll-indicator {
            display: none;
        }

        .hero-slide {
            height: auto;
            max-height: 400px;

            .wrapper {
                height: auto;
                max-height: 400px;
                padding: 200px 1.25em 100px 1.25em;
            }

            h2 {
                width: 100%;
                font-size: 32px;
                text-align: center;
                line-height: 1.4em;
                margin-top: 0;
                b { font-size: 40px; }
            }
        }
    }

    .trips {
        .scroll-indicator {
            display: none;
        }

        .trip-steps {
            width: 100%;
            flex-direction: column;

            .trip-step {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin: 1em 0;
                padding: 0 2em;

                .trip-icon {
                    display: none;
                }

                ul {
                    margin-left: 1em;
                }
            }
        }
    }

    .steps {
        .step-carousel {
            .step-carousel-slide {
                height: auto;

                .step-item-wrapper {
                    height: 55vh;
                    flex-direction: column;
                    margin-bottom: 6em;

                    h4 { font-size: 26px; margin: 1em; text-align: center; }
                    img { width: 140px; }
                    ul li { font-size: 17px; }
                }
            }
        }

        .step-grid {
            flex-wrap: wrap;

            .step-item {
                width: 30%;
                margin: 1em 1em 3em 1em;
            }
        }
    }

    .services-container {
        .services-title {
            font-size: 32px;
            padding: .75em 0;
        }
    }
}

@include handheld-mobile {
    .herospace {
        .hero-slide {
            .wrapper {
                padding-top: 250px;
            }
        }
    }

    .steps {
        .step-grid {
            .step-item {
                width: 40%;
                margin: 1em;

                h2 { font-size: 22px; }
            }
        }
    }

    .services-container {
        .service-section {
            .service-section-title {
                font-size: 24px;
                line-height: 1.3em;
            }
        }
    }
}
