// Alerts
.alert {
    width: 100%;
    display: block;
    font-size: 1em;
    line-height: 1.5em;
    font-weight: bold;
    color: $bblack;
    margin: 1em 0;
    padding: .5em 2%;

    &.alert-success { color: $bsuccess !important; }
    &.alert-danger { color: $bdanger !important; }
    &.alert-warning { color: $bwarning !important; }

    &.alert-success-border { border: 2px solid $bsuccess !important; }
    &.alert-danger-border { border: 2px solid $bdanger !important; }
    &.alert-warning-border { border: 2px solid $bwarning !important; }

    ul { margin-left: 2em; }
}

// Buttons
.btn {
    display: inline-block;
    font-family: $bfaf;
    font-weight: normal;
    background: none;
    border: none;
    border-radius: 4px;
    padding: 1em 2em;
    color: $bprimary-darker;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    outline: none;

    &.btn-primary {
        background: $bprimary;
        color: $bwhite;
        &:hover { background: lighten($bprimary, 5%); }
    }

    &.btn-secondary {
        background: $bsecondary;
        color: $bwhite;
        &:hover { background: lighten($bsecondary, 10%); }
    }

    &.btn-purple {
        background: $bpurple;
        color: $bwhite;
        &:hover { background: lighten($bpurple, 5%); }
    }
}

// Status messages
.has-success {
    color: $bsuccess;
    span { color: $bsuccess !important; }
    .form-input { color: $bsuccess !important; border: 1px solid $bsuccess !important; }
    select { border: 1px solid $bsuccess !important; }
}

.has-warning {
    color: $bwarning;
    span { color: $bwarning !important; }
    .form-input { color: $bwarning !important; border: 1px solid $bwarning !important; }
    select { border: 1px solid $bwarning !important; }
}

.has-error {
    color: $bdanger;
    span { color: $bdanger !important; }
    .form-input { color: $bdanger !important; border: 1px solid $bdanger !important; }
    select { border: 1px solid $bdanger !important; }
}
