.site-footer {
    display: block;
    background: $bprimary-darker;
    color: $bwhite;

    .wrapper {
        display: flex;
        justify-content: space-between;
    }

    .footer-col {
        padding: 2em;
        border-right: 1px solid rgba($bwhite, .3);

        .footer-col-title {
            display: block;
            margin-bottom: 1.5em;
            font-size: 1.35em;
            text-transform: uppercase;
            color: $bsecondary;
        }
    }

    .footer-nav {
        ul {
            list-style: none;
            margin-bottom: 1.5em;

            li {
                position: relative;
                line-height: 1.5em;
                padding: .5em 0 .5em 1em;
                &:before {
                    content: '\f105';
                    font-family: $ifaf;
                    color: $bsecondary;
                    margin: 0 .75em 0 -1em;
                }

                a {
                    color: $bwhite;
                    &:hover { text-decoration: underline; }
                }
            }
        }
    }

    .footer-contact {
        flex: 1;
        font-size: .9em;
        line-height: 1.5em;
        border: none;

        h6 {
            position: relative;
            font-size: 1.1em;
            line-height: 1.5em;
            margin: 1.25em 0 .25em 0;
            padding-left: 1.25em;
            &:before {
                content: '\f107';
                font-family: $ifaf;
                color: $bsecondary;
                margin: 0 .5em 0 -.75em;
            }
        }

        a {
            color: $bwhite;
            &:hover { text-decoration: underline; }
        }
    }

    .footer-social {
        width: 100px;
        border: none;
    }
}

@include tablet {
    .site-footer {
        .footer-social {
            display: none;
        }
    }
}

@include mobile {
    .site-footer {
        .wrapper {
            flex-direction: column;
        }

        .footer-col {
            padding: 1em;
            border: none;
        }

        .footer-contact {
            h6 {
                padding-left: 0;
                &:before { display: none; }
            }
        }
    }
}