.bubble-list {
    width: 100%;
    display: block;
    position: relative;
    padding: 3em 0;
    background: $gray;
    &.partner-bubble-list {
        padding-bottom: 5em;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -20px;
            @include triangle('down', 20px, $bwhite);
        }
    }

    .bubble-title {
        display: block;
        font-size: 30px;
        color: $bprimary-light;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 1em;
    }

    .bubble-flex {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .bubble-item {
        width: 21%;
        position: relative;
        display: inline-block;
        margin: 1em 1em 6em;
        text-align: center;
        cursor: pointer;

        .bubble-item-box {
            width: 95%;
            position: absolute;
            bottom: -1em;
            left: 2.5%;
            padding: 1.35em 1em;
            background: rgba($bprimary-lightest, .9);
            border-radius: 12px;
            text-align: center;
            color: $bprimary-dark;
            @include transition(bottom .1s ease-in-out);

            h2 {
                font-size: .75em;
                font-weight: normal;
                line-height: 1.5em;
                text-transform: uppercase;
            }
        }

        .partner-item-box {
            bottom: -4em;
            padding: 1em 1em 0 1em;

            h2 {
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
            }

            p {
                font-size: 14px;
                text-transform: none;
                margin-top: .5em;
            }

            .bubble-more-link {
                display: inline-block;
                &:after {
                    content: '\f0a9';
                    position: relative;
                    top: .35em;
                    font-family: $ifaf;
                    font-size: 2.2em;
                    color: $bprimary-light;
                }
            }
        }

        &:hover .bubble-item-box { bottom: -.75em; background: rgba($bprimary-lightest, 1); }
        &:hover .partner-item-box { bottom: -3.75em; }

        img {
            max-width: 100%;
            height: auto;
            border-radius: 50%;
        }
    }
}

@include tablet {
    .bubble-list {
        .bubble-flex {
            justify-content: flex-start;
        }

        .bubble-item {
            width: 27%;
            margin: 2em;
        }
    }
}

@include mobile {
    .bubble-list {
        flex-direction: column;

        .bubble-item {
            width: 100%;
            margin: .5em;

            .bubble-item-box {
                width: 100%;
                position: static;
                padding: 1em;

                h2 { font-size: 1em; }
            }

            img { display: none; }
        }
    }
}

@include tiny-mobile {
    .bubble-list {
        .bubble-title {
            font-size: 24px;
            line-height: 1.3em;
        }
    }
}
