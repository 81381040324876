@font-face {
    font-family: 'MuseoWeb';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/subset-MuseoSans-300.eot');
    src: url('../fonts/subset-MuseoSans-300.eot?#iefix') format('embedded-opentype'),
         url('../fonts/subset-MuseoSans-300.woff2') format('woff2'),
         url('../fonts/subset-MuseoSans-300.woff') format('woff'),
         url('../fonts/subset-MuseoSans-300.ttf') format('truetype'),
         url('../fonts/subset-MuseoSans-300.svg#MuseoWeb') format('svg');
}

@font-face {
    font-family: 'MuseoWeb';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/subset-MuseoSans-500.eot');
    src: url('../fonts/subset-MuseoSans-500.eot?#iefix') format('embedded-opentype'),
    url('../fonts/subset-MuseoSans-500.woff2') format('woff2'),
    url('../fonts/subset-MuseoSans-500.woff') format('woff'),
    url('../fonts/subset-MuseoSans-500.ttf') format('truetype'),
    url('../fonts/subset-MuseoSans-500.svg#MuseoWeb') format('svg');
}

@font-face {
    font-family: 'MuseoWeb';
    font-weight: bold;
    font-style: normal;
    src: url('../fonts/subset-MuseoSans-900.eot');
    src: url('../fonts/subset-MuseoSans-900.eot?#iefix') format('embedded-opentype'),
    url('../fonts/subset-MuseoSans-900.woff2') format('woff2'),
    url('../fonts/subset-MuseoSans-900.woff') format('woff'),
    url('../fonts/subset-MuseoSans-900.ttf') format('truetype'),
    url('../fonts/subset-MuseoSans-900.svg#MuseoWeb') format('svg');
}