.site-header {
    width: 100%;
    height: 75px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: block;
    background: rgba($bprimary-light, .6);
    &.scrolled {
        background: rgba($bprimary-light-alt, .95);
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        width: 139px;
        height: 36px;
        display: inline-block;
        margin-top: 7px;
        background: url(../img/humed-logo-white.svg) 0 0 no-repeat;
        background-size: 100% 100%;
    }

    .header-flex {
        display: flex;
        justify-content: space-between;
        flex: 1;
    }

    .nav-menu {
        flex: 1;
        position: relative;
        height: 75px;
        line-height: 75px;
        margin-left: 2em;

        .nav-toggler {
            display: none;
            font-size: 1.3em;
            text-transform: uppercase;
            color: $bwhite;

            i.fa { width: 20px; display: inline-block; text-align: center; }
        }

        ul {
            list-style: none;

            li {
                display: inline-block;
                border-left: 1px solid rgba($bwhite, .3);
                margin-left: -4px;

                a {
                    display: block;
                    height: 75px;
                    font-size: 14px;
                    line-height: 75px;
                    color: $bwhite;
                    padding: 0 1em;
                    text-transform: uppercase;
                    &:hover, &.open {
                        background: $bprimary;
                    }
                }

                ul.submenu {
                    width: 100%;
                    height: 60px;
                    background: rgba($bprimary, .95);
                    position: fixed;
                    top: 75px;
                    left: 0;
                    display: none;

                    .wrapper {
                        display: flex;
                        justify-content: flex-start;
                        padding-left: 167px;
                    }

                    li {
                        height: 30px;
                        margin: 15px 0 0 0;
                        &:first-child { border-left: none; }

                        a {
                            height: 60px;
                            font-size: 15px;
                            line-height: 64px;
                            margin-top: -15px;
                            &:hover {
                                background: none;
                                color: $bsecondary;
                            }
                        }
                    }
                }

                &.open-submenu {
                    a { background: $bprimary; }
                    ul.submenu { display: block; }
                }
            }
        }
    }

    .header-misc {
        display: flex;
        justify-content: space-between;
    }

    .languages {
        width: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        text-align: center;

        a {
            display: inline-block;
            color: $bwhite;
            font-size: .9em;
            margin: .15em;
            padding: .25em;
            text-transform: uppercase;
            &.selected { background: $bwhite; color: $bprimary; }
            &:hover { text-decoration: underline; }
        }
    }

    .search-indicator {
        height: 75px;
        position: relative;
        text-align: center;
        &.open {
            background: $bprimary;
        }

        .search-icon {
            display: block;
            color: $bwhite;
            font-size: 1.8em;
            line-height: 75px;
            margin: 0 .35em;
            cursor: pointer;
        }

        .search-form {
            position: absolute;
            top: 75px;
            right: 0;
            background: $bprimary;
            padding: .75em;
            display: none;

            .search-input {
                width: 480px;
                height: 55px;
                padding: 0 190px 0 10px;
                font: normal 1em $bfaf;
                color: $bblack;
                background: $bwhite;
                border: none;
                outline: none;
            }

            .search-submit {
                position: absolute;
                top: 16px;
                right: 15px;
                height: 47px;
                padding: 0 1.2em;
                border-radius: 4px;
                color: $bprimary;
                font-size: 16px;
                text-transform: uppercase;
            }
        }
    }

    .expert-button {
        margin-left: 1em;

        .btn {
            padding: 1em 1.25em;
            font-size: 13px;
            line-height: 1.2em;
            text-transform: uppercase;
        }
    }
}

// @include desktop {
//     .site-header {
//         .nav-menu {
//             margin-left: 1em;
//
//             ul {
//                 li {
//                     border: none;
//                     a { font-size: .9em; padding: 0 .85em; }
//                 }
//             }
//         }
//     }
// }

@include desktop {
    .site-header {
        .logo {
            width: 110px;
        }

        .nav-menu {
            margin-left: 1em;

            ul {
                li {
                    border: none;
                    a { font-size: .9em; padding: 0 .85em; }
                }
            }
        }
    }
}

@include tiny-desktop {
    .site-header {
        .logo {
            width: 130px;
            height: 30px;
        }

        .languages {
            width: auto;
            margin-right: 1em;
        }

        .nav-menu {
            .nav-toggler {
                display: inline-block;
                padding: 0 .75em;
                &.open {
                    background: $bprimary;
                    i.fa:before { content: '\f063'; }
                }
            }

            ul {
                margin-top: -2px;
                display: none;
                background: $bprimary;

                li {
                    width: 100%;
                    display: block;
                    padding: 0;
                    margin: 0;

                    a {
                        height: auto;
                        line-height: 1em;
                        font-size: 1.2em;
                        padding: 1.2em 1.5em;
                    }

                    ul.submenu {
                        position: static;
                        height: auto;
                        margin: 1em 0;
                        background: darken($bprimary, 10%);

                        .wrapper {
                            display: block;
                            padding: 0;
                        }

                        li {
                            height: auto;
                            margin: 0;
                            padding: 0;

                            a {
                                font-size: 1.1em;
                                line-height: 1em;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include mobile {
    .site-header {
        height: auto;
        position: absolute;

        .wrapper {
            flex-direction: column;
        }

        .logo {
            align-self: flex-start;
            margin: 1.5em 0 0 .5em;
        }

        .header-flex {
            width: 100%;
        }

        .nav-menu {
            height: auto;
            line-height: 1em;
            margin: .25em 0;

            .nav-toggler {
                padding: 1em .5em;
                display: block;
            }
        }

        .header-misc {
            position: absolute;
            top: -.25em;
            right: .5em;
        }
    }
}

@include tiny-mobile {
    .site-header {
        .logo {
            margin-top: 1.25em;
        }

        .header-misc {
            top: 1.25em;
        }

        .expert-button {
            position: absolute;
            top: 4.15em; right: 4em;
            .btn { padding: .5em 1em; font-size: 10px; }
        }

        .search-indicator {
            position: absolute;
            top: 1.5em;
            right: .5em;

            .search-form {
                top: 73px;

                .search-input { width: 17em; padding-right: 10px; }
                .search-submit { display: none; }
            }
        }
    }
}
