body {
    font-family: $bfaf;
    font-size: 16px;
    background: $bwhite;
    text-align: center;
    overflow-y: scroll;
    padding-top: 75px;
    &.no-padding {
        padding-top: 0;
    }

    @include tiny-desktop {
        font-size: 15px;
    }
}

.wrapper {
    width: $wrapper-width;
    position: relative;
    margin: 0 auto;
    text-align: left;

    @include desktop {
        width: 100%;
        padding: 0 1em;
    }

    @include tablet {
        padding: 0 .5em;
    }
}